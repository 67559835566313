import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import {
  Hero,
  FeaturedArticles,
  StaticServices,
  WhyAdudev,
} from "../../containers"
import {
  BreadCrumb,
  FeaturedStaticGenerator,
  MoreReasons,
  TitleV2,
} from "../../components"
import LogoSectionAbout from "../../components/Sections/logoSectionAbout"

import StaticGenerator from "../../constants/featuredStaticGenerator"
import reasons from "../../constants/reasons"
import StaticSiteCrumbs from "../../constants/BreadCrumbs/StaticSitesCrumbs"

const StaticPage = ({ data }) => {
  const {
    allStrapiArticle: { nodes: articles },
  } = data

  const image = getImage(
    data.strapiStaticPage.image.localFile.childImageSharp.gatsbyImageData
  )

  return (
    <section>
      <Hero image={image} title="Static Sites" />
      <BreadCrumb crumbs={StaticSiteCrumbs} />
      <StaticServices
        image={image}
        text={data.strapiStaticPage.text}
        title={data.strapiStaticPage.title}
      />
      <FeaturedStaticGenerator StaticGenerator={StaticGenerator} />

      <WhyAdudev />

      <MoreReasons reasons={reasons} />

      {/* <MoreReasons MoreReasons={MoreReasons} /> */}

      {/* filter articles on static site generation - todo have to add categories in strapi */}
      <FeaturedArticles
        articles={articles}
        title="Featured Articles"
        styleClass="underline"
      />
      <section className="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom">
        <div className="uk-container uk-padding-remove-top uk-margin-large-bottom">
          <div className="uk-margin-medium-top">
            <TitleV2
              title="Frequently Asked Questions"
              styleClass="underline"
            />
          </div>
          <hr className="uk-divider-icon uk-margin-medium-top" />
          <ul uk-accordion="true">
            {/* <li className="uk-open"> */}
            <li>
              <a className="uk-accordion-title" href="/">
                Static site generators
              </a>
              <div className="uk-accordion-content">
                <p>
                  A static site generator is a tool that generates a full static
                  HTML website based on raw data and a set of templates.
                  Essentially, a static site generator automates the task of
                  coding individual HTML pages and gets those pages ready to
                  serve to users ahead of time. Because these HTML pages are
                  pre-built, they can load very quickly in users' browsers.
                </p>
                <p>
                  Static site generators are an alternative to content
                  management systems (CMS) — another type of tool for managing
                  web content, generating webpages, and implementing templates.
                  (A template is a reusable format for web content; developers
                  use templates to avoid writing the same formatting over and
                  over.) Static site generators are typically part of a JAMstack
                  web development approach.
                </p>
              </div>
            </li>
            <li>
              <a className="uk-accordion-title" href="/">
                Static websites
              </a>
              <div className="uk-accordion-content">
                <p>
                  A static website is made up of one or more HTML webpages that
                  load the same way every time. Static websites contrast with
                  dynamic websites, which load differently based on any number
                  of changing data inputs, such as the user's location, the time
                  of day, or user actions. While static webpages are simple HTML
                  files that can load quickly, dynamic webpages require the
                  execution of JavaScript code within the browser in order to
                  render.
                </p>
              </div>
            </li>
            <li>
              <a className="uk-accordion-title" href="/">
                Frontend Frameworks
              </a>
              <div className="uk-accordion-content">
                <p>
                  A frontend framework is a collection of files and folders of
                  prebuilt code to help with the design and formatting of a
                  website. Common frontend frameworks include React, Gatsby JS
                  and Next.js, though there are many others.
                </p>
                <p>
                  Frontend frameworks are extremely helpful when developers are
                  initially building a website. However, frontend frameworks on
                  their own do not generate HTML webpages, unless a developer
                  uses additional tools. A static site generator can be used
                  along with a framework for a developer to rapidly get a fully
                  designed website or application ready for use. Most static
                  site generators allow developers to use any framework they
                  want.
                </p>
              </div>
            </li>
            <li>
              <a className="uk-accordion-title" href="/">
                Jamstack architecture
              </a>
              <div className="uk-accordion-content">
                <p>
                  The Jamstack architecture has many benefits, whether you’re
                  building a large e-commerce site, SaaS application or personal
                  blog.
                </p>
              </div>
            </li>
          </ul>
          <hr className="uk-divider-icon" />
        </div>
      </section>

      <LogoSectionAbout />
    </section>
  )
}

export default StaticPage

export const query = graphql`
  {
    strapiStaticPage {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
      text
    }
    allStrapiArticle(filter: { featured: { eq: true } }) {
      nodes {
        strapiId
        slug
        link
        title
        description
        date
        category {
          name
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 600
                height: 300
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
        author {
          name
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 50
                  height: 50
                  placeholder: BLURRED
                  formats: [AUTO]
                )
              }
            }
          }
        }
      }
    }
  }
`
