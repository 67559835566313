import React from "react"
import {
  FaGithub,
  FaCode,
  FaGoogle,
  FaSpaceShuttle,
  FaMoneyCheck,
  FaServer,
} from "react-icons/fa"
const data = [
  {
    id: 1,
    icon: <FaGithub className="service-icon" />,
    title: "Plugins, Themes, and Starters",
    text: `Get started quickly by using various Themes and Starter Templates for your website.`,
  },
  {
    id: 2,
    icon: <FaCode className="service-icon" />,
    title: "Modern Technology & Tools",
    text: `Great Ecosystem for Web Technology and Tools that Developers will know love.`,
  },
  {
    id: 3,
    icon: <FaGoogle className="service-icon" />,
    title: "Search engine optimization",
    text: `Gatsby comes up with SEO optimization setup as default, stright ot of the box.`,
  },
  {
    id: 4,
    icon: <FaSpaceShuttle className="service-icon" />,
    title: "Performance",
    text: `SGatsby is Built for Performance and with Performance in Mind, and continues to be.`,
  },
  {
    id: 5,
    icon: <FaMoneyCheck className="service-icon" />,
    title: "Scalability",
    text: `Scale your websites easily with fewer resources with JAM Stack Technology.`,
  },
  {
    id: 6,
    icon: <FaServer className="service-icon" />,
    title: "Jamstack architecture",
    text: `The modern way to build Websites and Apps that delivers better performance.`,
  },
]

export default data
